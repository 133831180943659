<template>
  <!-- <base-section id="product-carousel" space="0" style="background: black"> -->
  <div>
    <v-carousel
      :height="$vuetify.breakpoint.mdAndUp ? 500 : 320"
      class="carousel"
      ref="carousel"
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
    >
      <v-carousel-item
        class="carousel-item"
        v-for="(product, index) in products"
        :key="index"
      >
        <v-container class="fill-height align-center justify-center">
          <base-img
            max-height="200"
            class="align-center justify-center"
            :src="require(`@/assets/${product.image}.png`)"
            contain
            :width="$vuetify.breakpoint.mdAndUp ? '300px' : '200px'"
          />
          <!-- <base-body space="10" :text="product.text" customText="whiteText">
          </base-body> -->
          <p class="white--text text-justify">{{ product.text }}</p>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "SectionProductCarousel",

  mounted() {
    // Listen for arrow key press events in the document
    document.addEventListener("keydown", this.handleArrowKeys);
  },
  beforeDestroy() {
    // Clean up the event listener
    document.removeEventListener("keydown", this.handleArrowKeys);
  },

  data: () => ({
    products: [
      {
        image: "mavenx",
        text: "MavenX is a pre-funding deal pipeline engine designed specifically for alternate asset managers and credit lending processes. It provides a single source of truth for all deal-related data, enabling organizations to manage and track deals more efficiently. With customizable deal stages, automated alerts, and real-time reporting.",
      },
      {
        image: "tokenx-logo",
        text: "TokenX offers cutting-edge Data Security as a Service (D-SaaS) solutions, enabling startups and other organizations to achieve data privacy and security standards with ease. Our innovative API-based platform provides a simple and elegant solution that eliminates the need for costly and time-consuming in-house development.",
      },
      {
        image: "oneview-logo",
        text: "OneView is an application management system which consolidates all the custom in-house apps, PowerBI reports, power apps etc. and gives you a single source of truth for all your applications and access mechanism. Ensure seamless employee access management and save licensing costs by integrating third party applications such as PowerBI.",
      },
      {
        image: "firstvault-logo",
        text: "FirstVault is a security-first digital privacy vault which provides organizations a platform to store and share their sensitive documents securely alongwith other sensitive data such as Personally Identifiable Information (PII), login credentials, important documents and secrets using a 'Zero-Trust, Zero-Knowledge' security architecture.",
      },
    ],
  }),

  methods: {
    scroll(direction) {
      const wrapper = this.$refs.carousel;
      const scrollAmount = wrapper.offsetWidth * 0.9; // 90% of the carousel wrapper width
      wrapper.scrollLeft += scrollAmount * direction;
    },
    handleArrowKeys(event) {
      if (event.key === "ArrowLeft") {
        this.$refs.carousel.prev();
      } else if (event.key === "ArrowRight") {
        this.$refs.carousel.next();
      }
    },
  },
};
</script>
<style scoped>
/* .carousel-item {
  position: absolute;
  width: 100%;
  margin: 100px 100px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
} */

.carousel {
  background-color: black;
  color: white;
  /* position: relative;
  text-align: center; */
  box-sizing: border-box;
  touch-action: pan-y;
  overscroll-behavior: none;
}
</style>